<template>
  <div>
    <v-card-title class="d-flex align-center">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            icon
            :label="$t('generics.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="orders"
      :loading="loading"
      :search="search"
      :items-per-page="20"
      id="orders-table"
      :item-class="itemRowBackground"
      @click:row="onRowClick"
      sort-by="delivery"
      :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
    >
      <template v-slot:item.client.fullname="{ item }">
        <div>{{ item.client.fullname }} <v-icon v-if="isWoocommerce(item)">mdi-web</v-icon></div>
      </template>
      <template v-slot:item.delivery="{ item }">
        <div class="d-dlex column">
          <div>{{ getOrderDay(item.delivery) }}</div>
          <div>{{ getOrderDayString(item.delivery) }}</div>
          <div style="white-space: nowrap">
            {{ item.delivery.adjustedStartHour }}
          </div>
        </div>
      </template>
      <template v-slot:item.delivery.address="{ item }">
        <div v-if="item.delivery.type === 'delivery'">
          {{ item.delivery.address }}
        </div>
      </template>

      <template v-slot:item.box="{ item }">
        {{ numOfBoxes(item) }}
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status">{{ $t(`order.${item.status}`) }}</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Times from '../helpers/times'
import dayjs from 'dayjs'
export default {
  name: 'orders-table',
  data() {
    return {
      loading: false,
      search: '',
      headers: [
        {
          text: this.$t('collection.delivery'),
          value: 'delivery',
          class: 'primary lighten-1',
          sort: (a, b) => {
            return (
              this.parseToFullDelivaryNumberDate(a) -
              this.parseToFullDelivaryNumberDate(b)
            )
          },
        },
        {
          text: this.$t('order.num'),
          value: 'orderNum',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('client.name'),
          value: 'client.fullname',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('generics.mobile'),
          value: 'client.phone',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('order.info'),
          value: 'info',
          class: 'primary lighten-1',
        },
        {
          text: this.$t('generics.addressForDelivery'),
          value: 'delivery.address',
          class: 'primary lighten-1',
        },
        ,
        {
          text: this.$t('order.additionalInfo'),
          value: 'additionalInfo',
          class: 'primary lighten-1 ',
        },
        {
          text: this.$t('generics.notes'),
          value: 'delivery.info',
          class: 'primary lighten-1 ',
        },
        {
          text: this.$t('generics.internalNotesForTable'),
          value: 'internalNotes',
          class: 'primary lighten-1 ',
        },
        {
          text: this.$t('generics.boxNum'),
          value: 'box',
          class: 'primary lighten-1 ',
        },
        // {
        //   text: this.$t('generics.status'),
        //   value: 'status',
        //   class: 'primary lighten-1',
        // },
      ],
    }
  },
  props: {
    orders: { type: Array, required: true },
  },
  methods: {
    isWoocommerce(order) {
      return order?.menu.title.includes('Woocommerce')
    },
    numOfBoxes(order) {
      const products = order.menu.products
      return products.reduce((acc, curr) => {
        if (curr?.product?.box) return acc + curr?.quantity
        return acc
      }, 0)
    },
    getOrderDay(delivery) {
      return Times.extractDateString(delivery?.day)
    },
    getOrderDayString(delivery) {
      return Times.extractDayString(delivery?.day)
    },
    itemRowBackground(item) {
      if (item.paymentData.isPayed) return 'white'
      return 'error'
    },
    onRowClick(item) {
      this.$emit('onRowClick', item)
    },
    parseToFullDelivaryNumberDate(deliveryObj) {
      const { startHour } = deliveryObj
      const time = startHour.includes(' - ')
        ? startHour.split('-')[1]
        : startHour
      const [hour, minutes] = time.split(':').map(x => +x)
      return dayjs(deliveryObj.day)
        .set('hour', hour)
        .set('minute', minutes)
        .valueOf()
    },
  },
}
</script>
<style scoped lang="scss">
#orders-table {
  background-color: rgba(241, 234, 234, 0.877);
}
</style>
