import myAxios from '../../services/myAxios'
import dayjs from 'dayjs'
import _ from 'lodash'
import genericsServices from '../../services/generics'
import times from '@/helpers/times'

export default {
  state: {
    order: null, //chosen order
    orders: [],
    filtered: [],
  },
  getters: {
    orders: state => state.orders,
    ordersMap: state => {
      return state.orders.reduce((acc, cur) => {
        acc[cur._id] = cur
        return acc
      }, {})
    },
    todayOrders: state =>
      state.orders.filter(({ delivery: { day } }) =>
        dayjs().isSame(day, 'day')
      ),
    order: state => state.order,
    ordersFiltered: state => state.filtered,
    ordersFilterByDepartment:
      state =>
        ({ start, end, isDeliveryDate, department }) => {
          const orders = []
          state.orders.forEach(order => {
            // if order is not available today not inserted into the order list
            let [orderByTime] = genericsServices.filterOrders(
              [order],
              start,
              end,
              isDeliveryDate
            )
            if (!orderByTime) return

            const isInDepartment = order.menu.products.filter(p => {
              //checking department
              if (department && p.product?.department?.value !== department)
                return false
              return true
            })
            if (isInDepartment && isInDepartment.length) orders.push(order)
          })
          return orders
        },
    productsByType:
      (state, getters) =>
        ({ start, end, isDeliveryDate, department, search }) => {
          const productsData = []
          state.orders.forEach(order => {
            // if order is not available today not inserted into the order list
            let [orderByTime] = genericsServices.filterOrders(
              [order],
              start,
              end,
              isDeliveryDate
            )
            if (!orderByTime) return

            const {
              delivery,
              createdAt,
              client,
              menu: { products },
              status,
              _id: orderId,
              paymentData,
              orderNum,
              serveInfo,
              menuInfo,
              internalNotes,
            } = order
            products.forEach(p => {
              const productInfo = getProductInfo({
                product: p.product,
                productsObject: getters.productsObject,
              })
              const innerProduct = p.product || {}
              const innerProductWithDepartment = {
                ...innerProduct,
                department: productInfo.department,
              }
              const title = p.product?.title
              //checking department
              if (
                department &&
                innerProductWithDepartment?.department?.key !== department
              )
                return
              //checking search
              if (search && !title.includes(search)) return

              const prevData = productsData.find(
                i => i.title === p.product?.title
              )
              const infoNotes = {
                isReady: p.isReady,
                quantity: p?.quantity,
                info: p?.product?.info || '',
                clientName: client?.fullname || '-',
                deliveryTime: delivery?.startHour,
                day: delivery.day,
                serveInfo,
                orderNum,
                menuInfo,
                internalNotes,
              }
              const ids = {
                orderId,
                productId: p?.product?._id,
                productStatusId: p?._id,
              }
              const paidQuantity = status === 'payed' ? p.quantity : 0

              if (!prevData) {
                productsData.push({
                  totalQuantity: Math.max(p.quantity, 0),
                  infoNotes: infoNotes ? [infoNotes] : [],
                  title,
                  products: [innerProductWithDepartment],
                  ids: [ids],
                  paidQuantity: paidQuantity,
                  isReady: p.isReady,
                  ...productInfo,
                })
              } else {
                prevData.totalQuantity =
                  prevData.totalQuantity + Math.max(p.quantity, 0)
                if (infoNotes) prevData.infoNotes.push(infoNotes)
                if (!prevData.image && p.product?.image)
                  prevData.image = productInfo.image

                if (!prevData.category || p.product.catalogNumber)
                  prevData.category = productInfo.category

                if (!prevData.subCategory || p.product.catalogNumber)
                  prevData.subCategory = productInfo.subCategory
                if (!prevData.department || p.product.catalogNumber)
                  prevData.department = productInfo.department

                prevData.products.push(innerProductWithDepartment)
                prevData.ids.push(ids)
                prevData.paidQuantity = prevData.paidQuantity + paidQuantity
                prevData.isReady = prevData.isReady && p.isReady
              }
            })
          })
          return categorySort(productsData)
        },
    productsByTime: (state, getters) => {
      return state.orders
        .flatMap(
          ({
            delivery,
            createdAt,
            client,
            menu: { products },
            status,
            _id: orderId,
            orderNum,
          }) =>
            products.map(data => ({
              ...data,
              _id: data.product._id,
              menuProductId: data._id,
              delivery,
              client,
              status,
              orderId,
              createdAt,
              orderNum,
              product: {
                ...data.product,
                image: getProductInfo({
                  product: data.product,
                  productsObject: getters.productsObject,
                }).image,
              },
            }))
        )
        .sort((a, b) => times.timesFromStringComparison(a, b))
    },
    orderForDelivery: state =>
      state.orders.filter(({ delivery }) => delivery?.type === 'delivery'),
  },
  mutations: {
    //sets all orders
    'orders/set': (state, payload) => {
      //calculating usefull data for orders
      const orders = payload.map(o => {
        //adding the adjusted time
        const format = 'HH:mm'
        //is range
        if (
          /^([0-1]?[0-9]|2[0-3]):[0-5][0-9] ?- ?([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(
            o.delivery?.startHour
          )
        ) {
          const [end, start] = o.delivery?.startHour
            .split('-')
            .map(h => dayjs(h.trim(), format))
          const adjustedStart = start.format(format)
          const adjusteEnd = end.format(format)
          o.delivery.adjustedStartHour = `${adjusteEnd} - ${adjustedStart}`
        } else {
          o.delivery.adjustedStartHour = dayjs(
            o.delivery?.startHour,
            format
          ).format(format)
        }

        // shipping order info
        o['shipping'] =
          o.delivery?.type === 'self'
            ? 'איסןף עצמי'
            : o.delivery.address + ' ' + o.delivery.info
        //checking if late on payment
        const eventDay = dayjs(o.delivery.day)

        //if sunday
        if (!o.paymentData.isPayed) {
          if (eventDay.format('d') === '0') {
            o.isLateOnPayment = dayjs().isAfter(eventDay.subtract(3, 'day'))
          } else {
            o.isLateOnPayment = dayjs().isAfter(eventDay.subtract(1, 'day'))
          }
        } else {
          o.isLateOnPayment = false
        }
        return o
      })

      state.orders = orders
      state.filtered = [...state.orders]
    },
    //sets one order
    'order/set': (state, payload) => (state.order = payload),
    //filters the order's array by order's key and order's val
    'orders/filter': (state, { key, val }) => {
      state.filtered = !val
        ? [...state.orders]
        : state.orders.filter(f => f[key] === val)
    },
    //store one order
    'order/store': (state, payload) => state.orders.push(payload),
    //destroys one order
    'order/destroy': (state, id) =>
    (state.orders = state.orders.filter(item => {
      return item._id !== id
    })),
    //updates one order
    'order/update': (state, payload) => {
      state.orders = state.orders.map(item => {
        if (item._id === payload._id) {
          return _.merge(item, payload)
        }
        return item
      })
    },
    //updates product status in multiple orders
    'order/update-product-statuses': (state, payload) => {
      state.orders = state.orders.map(order => {
        order.menu.products = order.menu.products.map(prod => {
          if (payload.productIds.includes(prod._id))
            prod.isReady = payload.isReady
          return prod
        })
        return order
      })
    },
    //update one product status in order
    'order/update-product-status': (state, payload) => {
      state.orders = state.orders.map(order => {
        order.menu.products = order.menu.products.map(prod => {
          if (payload.productId === prod._id) prod.isReady = payload.isReady
          return prod
        })
        return order
      })
    },
    //updates one order
    'order/update-menu': (state, payload) => {
      state.orders = state.orders.map(item => {
        if (item._id === payload._id) {
          return { ...item, menu: payload.menu }
        }
        return item
      })
    },
  },
  actions: {
    //fetch all orders
    'order/index': async (context, payload = {}) => {
      const params = new URLSearchParams(payload).toString()
      const { data } = await myAxios.get(`/order?${params}`)
      context.commit('orders/set', data)
    },
    //fetch all orders in date range
    'order/shipments-in-date-range': async (context, payload) => {
      const { data } = await myAxios.get('/order/shipments-in-date-range', {
        params: payload,
      })
      context.commit('orders/set', data)
    },
    //fetch one order by id
    'order/show': async (context, payload) => {
      let { data } = await myAxios.get('/order/' + payload.id)
      context.commit('order/set', data)
    },
    //stores one order
    'order/store': async (context, payload) => {
      let { data } = await myAxios.post('/order', { ...payload })
      context.commit('order/store', data)
      context.commit('order/set', data)
      return data
    },
    //destroys one order
    'order/destroy': async (context, id) => {
      await myAxios.delete('/order/' + id)
      context.commit('user/destroy', id)
    },
    //updates one order by its id
    'order/update': async (context, payload) => {
      const { data } = await myAxios.put('/order/' + payload._id, payload)
      context.commit('order/update', data)
      return data
    },
    //change multiple product statuses in multiple orders
    'order/productStatuses': async (context, payload) => {
      await myAxios.post(`/order/pstatus`, payload)
      context.commit('order/update-product-statuses', payload)
    },
    //change product status in order
    'order/productStatus': async (context, payload) => {
      await myAxios.put(`/order/${payload.orderId}/product-status`, payload)
      context.commit('order/update-product-status', payload)
    },
    //change one product status in order menu
    'order/toggle-picked': async (context, payload) => {
      const { data } = await myAxios.post(
        `/order/${payload._id}/toggle-picked`,
        payload
      )
      context.commit('order/update-menu', data)
    },
    'order/sendPaymentLink': async (context, { orderId }) => {
      await myAxios.post(`/order/${orderId}/payment-mail`)
    },
    'order/deliveryApproval': async (context, { id, receiver, order }) => {
      const { data } = await myAxios.post(`/order/${id}/delivery-status`, {
        receiver,
      })
      context.commit('order/update', data)
    },
    'order/approvalPayment': async (context, { id, receiptId, order }) => {
      await myAxios.post(`/order/${id}/payment-status`, { receiptId })
      context.commit('order/update', { ...order, status: 'payed' })
    },
    'order/restore': async (context, payload) => {
      await myAxios.put('/order/' + payload._id, {
        ...payload,
        isDeleted: false,
      })
      context.commit('bid/destroy', { ...payload, id: payload._id })
    },
  },
}

/// Util functions
function getCategoriesForProduct({ product, productsObject }) {
  const { catalogNumber } = product
  let category = productsObject?.[catalogNumber]?.category
  category = category || product.category || ''
  let subCategory = productsObject?.[catalogNumber]?.subCategory
  subCategory = subCategory || product.subCategory || ''
  return { category, subCategory }
}
function getImageForProduct({ product, productsObject }) {
  const { catalogNumber } = product
  const imageFromRepo = productsObject?.[catalogNumber]?.image
  if (imageFromRepo) return imageFromRepo
  return product?.image || ''
}

function getProductInfo({ product, productsObject }) {
  const { catalogNumber } = product
  let category =
    productsObject?.[catalogNumber]?.category || product.category || ''
  let subCategory =
    productsObject?.[catalogNumber]?.subCategory || product.subCategory || ''

  const imageFromRepo = productsObject?.[catalogNumber]?.image || ''
  const image = imageFromRepo || product?.image || ''
  const department =
    productsObject?.[catalogNumber]?.department || product.department
  return { category, subCategory, image, department }
}

function categorySort(arr) {
  const sortCategory = _.sortBy(arr, [obj => obj.category || Infinity])
  const groupByCategory = _.groupBy(sortCategory, 'category')
  const groupBySubCategory = Object.values(groupByCategory).map(element => {
    const sortByText = _.orderBy(element, [
      obj => obj.subCategory?.text || Infinity,
      'title',
    ])
    let groupByText = _.groupBy(sortByText, 'subCategory.text')
    return Object.values(groupByText)
  })
  return _.flattenDeep(groupBySubCategory)
}
