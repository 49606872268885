<template>
  <v-container class="center-all-col">
    <v-row no-gutters class="mt-5" v-if="!onlyDoc">
      <v-col cols="12" class="center-all">
        {{ message }}
      </v-col>
      <v-col class="center-all pt-0">
        <v-slider
          v-model="items"
          track-color="grey"
          always-dirty
          min="5"
          max="60"
        >
          <template v-slot:prepend>
            <v-icon @click="decrement"> mdi-minus </v-icon>
          </template>

          <template v-slot:append>
            <v-icon @click="increment"> mdi-plus </v-icon>
          </template>
        </v-slider>
      </v-col>

      <v-col cols="12" class="center-all">
        <v-btn @click="generate">
          <v-icon>mdi-download</v-icon>
          <span>{{ $t('pdf.download') }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <vue-html2pdf
      :show-layout="showLayout"
      :float-layout="false"
      :enable-download="true"
      :paginate-elements-by-height="1400"
      :filename="fileName"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="legal"
      pdf-orientation="portrait"
      pdf-content-width="21.6cm"
      ref="html2Pdf"
      :class="isMobile ? 'mobilePdf' : ''"
    >
      <section slot="pdf-content">
        <slot />
      </section>
    </vue-html2pdf>
  </v-container>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'PdfDownload',
  props: {
    itemPerPage: { type: Number, default: 30 },
    showLayout: { type: Boolean, default: true },
    previewModal: {
      type: Boolean,
      default: process.env.NODE_ENV === 'production' ? false : true,
    },
    enableDownload: {
      type: Boolean,
      default: process.env.NODE_ENV !== 'production' ? true : false,
    },
    fileName: { type: String, default: 'pdfDoc' },
    pagesLength: { type: Number, default: 1 },
    onlyDoc: { type: Boolean, default: false },
  },
  components: { VueHtml2pdf },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 1000
    },
    items: {
      get() {
        return this.itemPerPage
      },
      set(v) {
        this.$emit('setItemPerPage', v)
      },
    },
    message() {
      return `${this.items} ${this.$tc('generics.item', 2)} ${this.$tc(
        'generics.toPage',
        2
      )}`
    },
  },
  mounted() {
    const params = new URLSearchParams(window.location.search)
    if (!params.get('auto')) return
    this.generate()
  },
  methods: {
    decrement() {
      this.items--
    },
    increment() {
      this.items++
    },
    async generate() {
      this.$refs.html2Pdf.generatePdf()
    },
  },
}
</script>
<style>
.mobilePdf {
  zoom: 0.4;
  margin-left: 90px;
}
</style>
